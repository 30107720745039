import "../stylesheets/application.scss";

require.context("../images", true);

/**
 * Import jQuery on a global var first
 */

window.$ = window.jQuery = require("jquery");

/**
 * Add onmount, which we will use for idempotent binding behavior to dom items
 */

$.onmount = require("onmount");

/**
 * Add libraries here
 */

require("bootstrap");
require("bootstrap-notify");
require("jquery-ujs");
require("nouislider");

/**
 * Add behavior javascripts
 */

require("./behaviors/auto-open-lightbox");
require("./behaviors/autocomplete-select");
require("./behaviors/carousel");
require("./behaviors/change-input");
require("./behaviors/child-radio-select");
require("./behaviors/clear-filterbox");
require("./behaviors/clipboard");
require("./behaviors/collapse");
require("./behaviors/cookie-consent");
require("./behaviors/countdown");
require("./behaviors/disable-form");
require("./behaviors/disabled");
require("./behaviors/donutchart");
require("./behaviors/expandable");
require("./behaviors/filter-projects");
require("./behaviors/filter-projects-on-load");
require("./behaviors/filter-projects-slider");
require("./behaviors/hide-target");
require("./behaviors/input-mask");
require("./behaviors/input-slider");
require("./behaviors/interest-rate-slider");
require("./behaviors/investment-planner");
require("./behaviors/keep-open");
require("./behaviors/lazyload");
require("./behaviors/maturity-slider");
require("./behaviors/mobile-menu");
require("./behaviors/notification");
require("./behaviors/number-animation");
require("./behaviors/open-lightbox");
require("./behaviors/password-reveal");
require("./behaviors/payment-method-selection");
require("./behaviors/popover");
require("./behaviors/remove-target");
require("./behaviors/require-target");
require("./behaviors/scroll-to");
require("./behaviors/searchbox");
require("./behaviors/select-all");
require("./behaviors/select-amount");
require("./behaviors/select-amount-slider");
require("./behaviors/set-input-on-change");
require("./behaviors/show-target");
require("./behaviors/stacked-bar-chart");
require("./behaviors/submit-on-change");
require("./behaviors/tabs");
require("./behaviors/toggle-element");
require("./behaviors/toggle-input-fields");
require("./behaviors/toggle-target");
require("./behaviors/toggle-text");
require("./behaviors/toggle-warning-on-select");
require("./behaviors/tooltip");
require("./behaviors/track");
require("./behaviors/tutorial");
require("./behaviors/update-payment-fees");
require("./behaviors/update-project-counts");
require("./behaviors/xhr-form");
require("./behaviors/sticky");

/**
 * Add modules here
 */

require("./modules/jquery-match-height");

document.addEventListener("DOMContentLoaded", function() {
  $.onmount();
});

